import produce from 'immer';
import { put, select, takeLatest, take } from 'redux-saga/effects';
import {
  COMMON_CHECK_AUTH,
  COMMON_IS_LOGGED_IN,
  COMMON_IS_LOGGED_OUT,
  COMMON_IS_TOKEN_EXPIRED,
} from "./constants";
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGIN_WORKFLOW_DEFAULT, LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./loginWorkflowConstants";
import { selectIsLoggedIn, selectIsTokenExpired } from "./selectors";

export function commonCheckIsAuth() {
  return {
    type: COMMON_CHECK_AUTH,
  };
}

function* doCheckIsAuth() {
  const isLoggedIn = yield select(selectIsLoggedIn);
  const isTokenExpired = yield select(selectIsTokenExpired);

  if (isLoggedIn) {
    if (isTokenExpired){
      yield put({
        type: COMMON_IS_TOKEN_EXPIRED,
      });
    } else {
      yield put({
        type: COMMON_IS_LOGGED_IN,
      });
    }
  } else {
    yield put({
      type: COMMON_IS_LOGGED_OUT,
    });
  }
}

export function* switchCheckIsAuth() {
  yield takeLatest(COMMON_CHECK_AUTH, doCheckIsAuth);
  yield take(LOCATION_CHANGE);
  yield put(commonCheckIsAuth());
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_IS_LOGGED_IN:
        draft.authInfo.isLoggedIn = true;
        break;
      case COMMON_CHECK_AUTH:
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      case COMMON_IS_LOGGED_OUT:
        draft.authInfo.isLoggedIn = false;
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      case COMMON_IS_TOKEN_EXPIRED:
        draft.loginWorkflow = LOGIN_WORKFLOW_EXPIRED_TOKEN;
        draft.authInfo.isTokenExpired = true;
        break;
      default:
        return state;
    }
  });
