import Axios from 'axios';
import HttpStatus from 'http-status-codes';
import { jwtDecode } from "jwt-decode";

import { i18n } from '../../../common/i18n-loader';
import {
  badRequestError,
  genericError,
  invalidLoginError,
  notFoundError,
  unavailableError,
} from '../../../common/messages';
import { commonKeycloakDoLogin } from '../redux/keycloak';
import { selectAccessToken, selectIsLoggedIn, selectIsTokenExpired } from "../redux/selectors";
import { commonSetTokenExpired, commonTokenSet, commonTokenUnset } from "../redux/actions";
import { getStore } from '../../../common/configExternalStore';

export async function keycloakAuthLogin(tokens) {
  try {
    // if this call succedes, the token is valid
    const response = await Axios.get('/api/auth', {
      headers: { Authorization: `Bearer ${tokens.token}` },
    });
    const { exp } = jwtDecode(tokens.token);
    const expirationTime = exp * 1000;

    const session = {
      data: {
        token: tokens.token,
        idToken: tokens.idToken,
        refreshToken: tokens.refreshToken,
        expiresAt: new Date(expirationTime),
      },
    };

    setSession(session, true);

    return {
      client: response.data.client,
      username: response.data.userId,
      email: response.data.email,
    };
  } catch (error) {
    clearSession();

    let message;

    if (!!error.response) {
      const { response } = error;
      switch (response.status) {
        case HttpStatus.UNAUTHORIZED:
          commonKeycloakDoLogin();
          message = invalidLoginError;
          break;
        case HttpStatus.BAD_REQUEST:
          if (!!response.data && !!response.data.message) {
            message = response.data.message;
          } else {
            message = badRequestError;
          }
          break;
        case HttpStatus.NOT_FOUND:
          message = notFoundError;
          break;
        default:
        case HttpStatus.INTERNAL_SERVER_ERROR:
          message = genericError;
      }
    } else {
      message = unavailableError;
    }

    throw Error(i18n._(message));
  }
}

export async function authLogin(username, password, client) {
  try {
    const response = await Axios.post('/api/auth', { username, password, client });

    const { exp } = jwtDecode(response.data.token);
    const expirationTime = exp * 1000;

    setSession({
      data: {
        token: response.data.token,
        expiresAt: new Date(expirationTime),
      },
    });

    return {
      client: response.data.client,
      username: response.data.userId,
      email: response.data.email,
    };
  } catch (error) {
    clearSession();

    let message;

    if (!!error.response) {
      const { response } = error;
      switch (response.status) {
        case HttpStatus.BAD_REQUEST:
          if (!!response.data && !!response.data.message) {
            message = response.data.message;
          } else {
            message = badRequestError;
          }
          break;
        case HttpStatus.NOT_FOUND:
          message = notFoundError;
          break;
        default:
        case HttpStatus.INTERNAL_SERVER_ERROR:
          message = genericError;
      }
    } else {
      message = unavailableError;
    }

    throw Error(i18n._(message));
  }
}

export function authLogout() {
  clearSession();
}

export function authIsAuthenticated() {
  const isLoggedIn = selectIsLoggedIn(getStore().getState());
  const isTokenExpired = selectIsTokenExpired(getStore().getState());

  return isLoggedIn && !isTokenExpired ;
}

export function authGetToken() {
  return selectAccessToken(getStore().getState());
}

export function setTokenExpired() {
  return getStore().dispatch(commonSetTokenExpired(true));
}

function setSession(response, isSsoLogin = false) {
  getStore().dispatch(commonTokenSet({
    token: response.data.token,
    refreshToken: isSsoLogin ? response.data.token : null,
    idToken: isSsoLogin ? response.data.token : null,
    expiresAt: response.data.expiresAt,
  }, isSsoLogin));
}

function clearSession() {
  getStore().dispatch(commonTokenUnset());
}
