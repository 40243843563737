/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useCallback } from 'react';
import flatpickr from 'flatpickr';
import { DateTime } from 'luxon';
import { variables } from '../../common/theme/variables';

import Input from './Input';
import { Icon } from './Icon';
import { colors } from '../../common/theme/colors';

const styles = {
  datepicker: {
    display: 'grid',

    gridTemplateColumns: 'max-content min-content',
    [variables.breakpoints.xl]: {
      gridTemplateColumns: 'auto min-content',
    },
    gridGap: '0em 1em',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'transparent',
  },
  hasError: {
    borderColor: colors.errors,
  },
};

export const DatePicker = (props) => {
  const {
    name,
    label,
    onChange,
    onClose,
    value,
    className,
    placeholder,
    minimal = false,
    inheritedContainerStyle,
    inheritedInputStyle,
    hasError,
    disabled
  } = props;

  const ref = useRef(null);
  const instance = useRef(null);

  const format = 'd.m.Y';
  const luxonFormat = 'd.m.yy';
  const serverFormat = 'MM/dd/yyyy HH:mm:ss';

  useEffect(() => {
    if (!!ref.current) {
      instance.current = flatpickr(ref.current, {
        dateFormat: format,
        enableTime: false,
        allowInput: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!!instance.current) {
      const dt = DateTime.fromFormat(value || '', serverFormat);
      if (dt.isValid) {
        instance.current.setDate(dt.toJSDate(), true);
      } else {
        instance.current.clear();
      }
    }
  }, [value]);

  useEffect(() => {
    if (!!instance.current) {
      instance.current.config.onChange.length = 0;
      instance.current.config.onChange.push((dates) => {
        let changeValue = '';
        if (!!dates && dates.length) {
          changeValue = DateTime.fromJSDate(dates[0]).toFormat(serverFormat);
        }
        if (value !== changeValue) {
          onChange(changeValue);
        }
      });
    }
  }, [onChange, value]);

  useEffect(() => {
    if (!!instance.current) {
      instance.current.config.onClose.length = 0;
      instance.current.config.onClose.push(() => onClose());
    }
  }, [onClose]);

  const handleChange = useCallback((evt) => {}, []);
  const handleBlur = useCallback((evt) => {
    const value = evt.currentTarget.value;
    const dt = DateTime.fromFormat(value, luxonFormat);
    if (dt.isValid) {
      instance.current.setDate(value, true);
    } else {
      instance.current.clear();
    }
  }, []);

  const handleOpen = useCallback((evt) => {
    if (!!instance.current) {
      instance.current.toggle();
    }
    evt.preventDefault();
  }, []);

  return (
    <div css={{ ...styles.datepicker, ...inheritedContainerStyle }}>
      <Input
        ref={ref}
        disabled={disabled}
        data-input
        placeholder={placeholder || label}
        type="text"
        name={name}
        autoComplete="off"
        className={className}
        onChange={handleChange}
        onBlur={handleBlur}
        css={{ ...inheritedInputStyle, ...(hasError && styles.hasError) }}
      />
      {!minimal && (
        <button css={styles.button} onClick={handleOpen}>
          <Icon icon="calendar" size="2x" title="toggle" />
        </button>
      )}
    </div>
  );
};
