import { createMatchSelector } from 'connected-react-router';
import {
  any,
  chain,
  filter,
  find,
  indexBy,
  prop,
  concat,
  reduce,
  has,
  mergeDeepRight,
  reject,
  isNil,
  forEach,
} from 'ramda';
import { createSelector } from 'reselect';
import {
  getOrderedComponents,
  getOrderedElementsTemplate,
  isNotEmptyElementInput,
  getIndexedElements,
} from '../../common/Functions';
import { getLocalizedNameForComponent } from '../fill-step/Functions';
import {
  selectOfflineProtocols,
  selectPdfs,
  selectProtocols,
  selectQueues,
  selectLeavingAgreements,
  selectErrorSynchronizationProtocols,
  selectHasOnSiteWorkflow,
} from '../../common/redux/selectors';

export const selectProtocolIdRoute = createSelector(
  (state) => state,
  createMatchSelector({ path: '/acceptance-protocol/:id/:step' }),
);

export const selectCurrentId = createSelector(selectProtocolIdRoute, (route) =>
  !!route && !!route.params ? route.params.id : null,
);

export const selectCurrentStep = createSelector(selectProtocolIdRoute, (route) =>
  !!route && !!route.params ? route.params.step : null,
);

export const selectCurrentProtocol = createSelector(
  selectProtocols,
  selectCurrentId,
  (protocols, currentId) =>
    !!currentId ? find((protocol) => protocol.id === currentId, protocols || []) : null,
);

export const selectCurrentLeavingAgreement = createSelector(
  selectLeavingAgreements,
  selectCurrentId,
  (leavingAgreements, currentId) =>
    !!currentId
      ? leavingAgreements.find((leavingAgreement) => leavingAgreement.protocolId === currentId)
      : null,
);

export const selectCurrentHasOnSiteWorkflow = createSelector(
  selectHasOnSiteWorkflow,
  (hasOnSiteWorkflow) => hasOnSiteWorkflow,
);

export const selectCurrentProtocolDataSection = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol.rentalData.datenbereichCd,
);

export const selectCurrentLeavingAgreementHasOldTenantSigned = createSelector(
  selectCurrentLeavingAgreement,
  (leavingAgreement) =>
    leavingAgreement &&
    leavingAgreement.signatures &&
    leavingAgreement.signatures.oldTenant &&
    !!leavingAgreement.signatures.oldTenant.data,
);

export const selectCurrentLeavingAgreementHasManagerSigned = createSelector(
  selectCurrentLeavingAgreement,
  (leavingAgreement) =>
    leavingAgreement &&
    leavingAgreement.signatures &&
    leavingAgreement.signatures.manager &&
    !!leavingAgreement.signatures.manager.data,
);

export const selectIsCurrentProtocolOffline = createSelector(
  selectOfflineProtocols,
  selectCurrentId,
  (offlineProtocols, currentId) =>
    !!currentId ? offlineProtocols.indexOf(currentId) !== -1 : null,
);

export const selectProtocolUser = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.user,
);

export const selectRentalData = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol && protocol.rentalData,
);

export const selectRentalObjectId = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol && protocol.rentalObjectId,
);

export const selectIsWithRemReference = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol && protocol.isWithRemReference,
);

export const selectSendStep = createSelector(selectCurrentProtocol, (protocol) => {
  const getValueFromBasedataInputs = (type, id, name) => {
    const item =
      !!protocol &&
      !!protocol.input &&
      !!protocol.input.basedataInputs &&
      find((x) => x.id === id, protocol.input.basedataInputs);
    if (!!item && !!item.fields) {
      const field = find((x) => x.fieldname === name, item.fields);
      return {
        type: type,
        emails: !!field ? field.value : '',
        send: false,
      };
    } else {
      return null;
    }
  };

  const getValueFromSendStep = (type) =>
    !!protocol &&
    !!protocol.input &&
    !!protocol.input.sendStep &&
    find((x) => x.type === type, protocol.input.sendStep);

  const defaultValue = (type) => ({
    type,
    emails: '',
    send: false,
  });

  const oldTenant =
    getValueFromSendStep('oldTenant') ||
    getValueFromBasedataInputs('oldTenant', 'oldRentalPerson', 'VormieterEmail') ||
    defaultValue('oldTenant');
  const oldTenant_2 =
    getValueFromSendStep('oldTenant_2') ||
    getValueFromBasedataInputs('oldTenant_2', 'oldRentalPerson_2', 'VormieterEmail');
  const newTenant =
    getValueFromSendStep('newTenant') ||
    getValueFromBasedataInputs('newTenant', 'newRentalPerson', 'NachmieterEmail') ||
    defaultValue('newTenant');
  const newTenant_2 =
    getValueFromSendStep('newTenant_2') ||
    getValueFromBasedataInputs('newTenant_2', 'newRentalPerson_2', 'NachmieterEmail');
  const manager = getValueFromSendStep('manager') || defaultValue('manager');
  const otherMails = getValueFromSendStep('otherMails') || defaultValue('otherMails');

  return reject(isNil, [oldTenant, oldTenant_2, newTenant, newTenant_2, manager, otherMails]);
});

export const selectProgressDetails = createSelector(selectCurrentProtocol, (protocol) =>
  !!protocol && !!protocol.input ? protocol.input.progressDetails : null,
);

export const selectSignStep = createSelector(
  selectProgressDetails,
  (progressDetails) => !!progressDetails && progressDetails.signStep,
);

export const selectComponentsOrderInput = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol && protocol.input.componentsOrderInput,
);

export const selectProtocolSignatures = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.input.signatures,
);

export const selectProtocolSignedAt = createSelector(
  selectProtocolSignatures,
  (signatures) => !!signatures && signatures.signedAt,
);

export const selectHasSignedOldTenant = createSelector(
  selectProtocolSignatures,
  (protocolSignatures) =>
    !!protocolSignatures &&
    !!protocolSignatures.oldTenant &&
    (!!protocolSignatures.oldTenant.data || protocolSignatures.oldTenant.signatureRefused),
);

export const selectHasSignedNewTenant = createSelector(
  selectProtocolSignatures,
  (protocolSignatures) =>
    !!protocolSignatures &&
    !!protocolSignatures.newTenant &&
    (!!protocolSignatures.newTenant.data || protocolSignatures.newTenant.signatureRefused),
);

export const selectHasSignedManager = createSelector(
  selectProtocolSignatures,
  (protocolSignatures) => !!protocolSignatures && !!protocolSignatures.manager.data,
);

export const selectIsSignStep = createSelector(selectCurrentStep, (step) => step === 'sign-step');

export const selectIsLeavingAgreementStep = createSelector(
  selectCurrentStep,
  (step) => step === 'leaving-agreement-step' || step === 'leaving-agreement-sign',
);

export const selectIsProtocolReadonly = createSelector(
  selectProtocolSignatures,
  (protocolSignatures) =>
    !!protocolSignatures &&
    (!!protocolSignatures.oldTenant.data ||
      protocolSignatures.oldTenant.signatureRefused ||
      !!protocolSignatures.newTenant.data ||
      protocolSignatures.newTenant.signatureRefused ||
      !!protocolSignatures.manager.data),
);

export const selectIsLanguageLocked = createSelector(
  selectIsProtocolReadonly,
  selectIsSignStep,
  (isProtocolReadOnly, isSignStep) => isProtocolReadOnly || isSignStep,
);

export const selectProtocolLanguage = createSelector(selectCurrentProtocol, (protocol) =>
  !!protocol && !!protocol.input ? protocol.input.lockLanguage : null,
);

export const selectProtocolTemplate = createSelector(
  selectCurrentProtocol,
  (protocol) => protocol && protocol.template,
);

export const selectElementsTemplate = createSelector(
  selectProtocolTemplate,
  (template) => template && template.elements,
);

export const selectComponentById = (id) => {
  return createSelector(
    selectCurrentProtocol,
    (protocol) =>
      !!protocol &&
      !!protocol.input &&
      !!protocol.input.componentsInput &&
      find((p) => p.id === id, protocol.input.componentsInput),
  );
};

export const selectIndexedElementsTemplate = createSelector(
  selectElementsTemplate,
  (templateElements) => templateElements && indexBy(prop('id'), templateElements),
);

export const selectComponentsTemplate = createSelector(
  selectProtocolTemplate,
  (template) => template && template.components,
);

export const selectIndexedComponentsTemplate = createSelector(
  selectComponentsTemplate,
  (components) => components && indexBy(prop('id'), components),
);

export const selectAttributesTemplate = createSelector(
  selectProtocolTemplate,
  (template) => template && template.attributes,
);

export const selectIndexedAttributesTemplate = createSelector(
  selectAttributesTemplate,
  (templateAttributes) => templateAttributes && indexBy(prop('id'), templateAttributes),
);

export const selectAttributeCategoriesTemplate = createSelector(
  selectProtocolTemplate,
  (template) => template && template.attributeCategories,
);

export const selectIndexedAttributeCategoriesTemplate = createSelector(
  selectAttributeCategoriesTemplate,
  (templateAttributeCategories) =>
    templateAttributeCategories && indexBy(prop('id'), templateAttributeCategories),
);

export const selectTemplateCosts = createSelector(
  selectProtocolTemplate,
  (template) => template && template.costs,
);

export const selectIndexedTemplateCosts = createSelector(
  selectTemplateCosts,
  (templateCosts) => templateCosts && indexBy(prop('id'), templateCosts),
);

export const selectBasedataTemplate = createSelector(
  selectProtocolTemplate,
  (template) => template && template.basedata,
);

export const selectIndexedBasedataTemplate = createSelector(
  selectBasedataTemplate,
  (templateBasedata) => templateBasedata && indexBy(prop('id'), templateBasedata),
);

export const selectProtocolTemplateName = createSelector(
  selectProtocolTemplate,
  (template) => template && template.templateName,
);

export const selectBasedataInput = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.input.basedataInputs,
);

export const selectIndexedBasedataInput = createSelector(
  selectBasedataInput,
  (basedataInput) => !!basedataInput && indexBy(prop('id'), basedataInput),
);

export const selectIsTenantsErased = (protocolId) =>
  createSelector(selectProtocols, (protocols) => {
    const protocol = protocols.find((p) => p.id === protocolId);
    const basedataInputs = !!protocol && protocol.input.basedataInputs;
    const rentalPersons = {};
    !!basedataInputs &&
      basedataInputs.forEach((basedataInput) => {
        if (basedataInput.id === 'oldRentalPerson' || basedataInput.id === 'newRentalPerson') {
          rentalPersons[basedataInput.id] = !!basedataInput.isErased;
        }
      });

    return rentalPersons;
  });

export const selectComponentsInput = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.input.componentsInput,
);

export const selectPreviousProtocolId = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.previousProtocol.id,
);

export const selectPreviousComponentsInput = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.input.previousComponentsInput,
);

export const selectPreviousComponentsOrderInput = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.input.previousComponentOrderInput,
);

export const selectIndexedComponentsInput = createSelector(
  selectComponentsInput,
  (components) => !!components && indexBy(prop('id'), components),
);

export const selectIndexedPreviousComponentsInput = createSelector(
  selectPreviousComponentsInput,
  (previousComponents) => !!previousComponents && indexBy(prop('id'), previousComponents),
);

export const selectProtocolImages = createSelector(
  selectComponentsInput,
  (componentsInput) =>
    !!componentsInput &&
    chain(
      (ci) =>
        chain(
          (el) => el.images,
          filter((el) => !!el.images, ci.elements),
        ),
      filter((ci) => !!ci.elements, componentsInput),
    ),
);

export const selectProtocolReportImages = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.reportTemplate.images,
);

export const selectCurrentQueueLength = createSelector(
  selectQueues,
  selectCurrentId,
  (queues, currentId) => {
    if (!queues || !currentId || !queues[currentId]) {
      return 0; // Safeguarding against undefined or null
    }
    return queues[currentId].operations.length;
  },
);

export const selectCurrentHasQueue = createSelector(
  selectCurrentQueueLength,
  (currentQueueLength) => (!!currentQueueLength ? currentQueueLength : false),
);

const selectOldRentalPersonFields = createSelector(selectCurrentProtocol, (protocol) => {
  if (!!protocol) {
    const oldRentalPerson = find(
      (el) => el.id === 'oldRentalPerson',
      protocol.input.basedataInputs,
    );

    return !!oldRentalPerson && oldRentalPerson.fields;
  }
});

const selectSecondOldRentalPersonFields = createSelector(selectCurrentProtocol, (protocol) => {
  if (!!protocol) {
    const oldRentalPerson2 = find(
      (el) => el.id === 'oldRentalPerson_2',
      protocol.input.basedataInputs,
    );

    return !!oldRentalPerson2 && oldRentalPerson2.fields;
  }
});

const selectNewTenantFields = createSelector(selectCurrentProtocol, (protocol) => {
  if (!!protocol) {
    const newRentalPerson = find(
      (el) => el.id === 'newRentalPerson',
      protocol.input.basedataInputs,
    );

    return !!newRentalPerson && newRentalPerson.fields;
  }
});

const selectSecondNewTenantFields = createSelector(selectCurrentProtocol, (protocol) => {
  if (!!protocol) {
    const newRentalPerson2 = find(
      (el) => el.id === 'newRentalPerson_2',
      protocol.input.basedataInputs,
    );

    return !!newRentalPerson2 && newRentalPerson2.fields;
  }
});

export const selectOldTenantInfo = createSelector(
  selectOldRentalPersonFields,
  (oldRentalPersonFields) => {
    if (!oldRentalPersonFields) {
      return null;
    }
    const getValueFromFields = (value) => {
      const field = find((field) => field.fieldname === value, oldRentalPersonFields);
      return !!field && !!field.value ? field.value : '';
    };

    return {
      name: getValueFromFields('VormieterVorname'),
      surename: getValueFromFields('VormieterName'),
      street: getValueFromFields('VormieterStrasse'),
      postalCode: getValueFromFields('VormieterPlz'),
      place: getValueFromFields('VormieterOrt'),
      privatePhone: getValueFromFields('VormieterTelefon'),
      privatePhone2: getValueFromFields('VormieterTelefon2'),
      mobilePhone: getValueFromFields('VormieterHandyNr'),
    };
  },
);

export const selectSecondOldTenantInfo = createSelector(
  selectSecondOldRentalPersonFields,
  (secondOldRentalPersonFields) => {
    if (!secondOldRentalPersonFields) {
      return null;
    }
    const getValueFromFields = (value) => {
      const field = find((field) => field.fieldname === value, secondOldRentalPersonFields);
      return !!field && !!field.value ? field.value : '';
    };

    return {
      name: getValueFromFields('VormieterVorname'),
      surename: getValueFromFields('VormieterName'),
      street: getValueFromFields('VormieterStrasse'),
      postalCode: getValueFromFields('VormieterPlz'),
      place: getValueFromFields('VormieterOrt'),
      privatePhone: getValueFromFields('VormieterTelefon'),
      privatePhone2: getValueFromFields('VormieterTelefon2'),
      mobilePhone: getValueFromFields('VormieterHandyNr'),
    };
  },
);

export const selectNewTenantInfo = createSelector(selectNewTenantFields, (newTenantFields) => {
  if (!newTenantFields) {
    return null;
  }
  const getValueFromFields = (value) => {
    const field = find((field) => field.fieldname === value, newTenantFields);
    return !!field && !!field.value ? field.value : '';
  };
  return {
    name: getValueFromFields('NachmieterVorname'),
    surename: getValueFromFields('NachmieterName'),
  };
});

export const selectSecondNewTenantInfo = createSelector(
  selectSecondNewTenantFields,
  (secondNewTenantFields) => {
    if (!secondNewTenantFields) {
      return null;
    }
    const getValueFromFields = (value) => {
      const field = find((field) => field.fieldname === value, secondNewTenantFields);
      return !!field && !!field.value ? field.value : '';
    };
    return {
      name: getValueFromFields('NachmieterVorname'),
      surename: getValueFromFields('NachmieterName'),
    };
  },
);

export const selectCurrentProtocolPrevious = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && protocol.previousProtocol,
);

export const selectCurrentProtocolHasPrevious = createSelector(
  selectCurrentProtocol,
  (protocol) => !!protocol && !!protocol.previousProtocol,
);

export const selectCurrentProtocolHasPreviousPdf = createSelector(
  selectCurrentId,
  selectPdfs,
  (currentId, pdfs) => !!pdfs[currentId],
);

export const selectCurrentProtocolHasPreviousLeavingAgreement = createSelector(
  selectCurrentProtocolPrevious,
  (protocol) => protocol && protocol.hasLeavingAgreement,
);

export const selectCurrentProtocolHasPreviousComponentsInput = createSelector(
  selectCurrentProtocol,
  (protocol) =>
    !!protocol &&
    !!protocol.input &&
    !!protocol.input.previousComponentsInput &&
    any((c) => !!c.elements && !!c.elements.length)(protocol.input.previousComponentsInput),
);

export const selectCurrentProtocolComponentsHasBeenReviewed = createSelector(
  selectComponentsInput,
  selectIndexedComponentsTemplate,
  (componentsInput, template) => {
    // Ensure componentsInput exists and is an array
    if (!componentsInput) return false;

    return componentsInput?.every((component) => {
      const templateForType = template[component.typeId];

      // If there's no template for this component type, skip review
      if (!templateForType) return true;

      // Check all elements: removed OR not copied
      return (component.elements || [])?.every((element) => element.removed || !element.copied);
    });
  },
);

export const selectOrderedComponents = createSelector(
  selectComponentsInput,
  selectComponentsOrderInput,
  selectIndexedComponentsTemplate,
  (componentsInput, componentsOrderInput, indexedComponentsTemplate) =>
    !!componentsInput &&
    !!indexedComponentsTemplate &&
    getOrderedComponents(componentsInput, componentsOrderInput, indexedComponentsTemplate),
);

export const selectComponentInputsIndexed = createSelector(
  selectOrderedComponents,
  selectIndexedElementsTemplate,
  (orderedComponents, indexedElementsTemplate) => {
    const filteredOrderedComponents = filter(
      (c) =>
        !!c.componentInput &&
        !!c.componentInput.elements &&
        any(isNotEmptyElementInput)(c.componentInput.elements),
      orderedComponents,
    );

    const indexedElements = [];
    let indexedElementsCounter = 0;

    for (const { componentInput, componentTemplate } of filteredOrderedComponents) {
      const orderedElements = getOrderedElementsTemplate(
        componentInput.elements,
        componentInput.elementsOrder,
        indexedElementsTemplate,
        componentTemplate,
      );

      const indexedElementInputs =
        componentInput.elements && indexBy(prop('id'), componentInput.elements);

      const filteredOrderedElements = filter(
        (el) => !!el && isNotEmptyElementInput(indexedElementInputs[el.id]),
        orderedElements,
      );

      for (const elementTemplate of filteredOrderedElements) {
        indexedElementsCounter++;
        // We used a negative componentInput ID for components without template
        indexedElements.push({
          elementId: elementTemplate.id,
          componentId: componentInput.id,
          index: indexedElementsCounter,
        });
      }
    }

    return indexedElements;
  },
);

export const selectProtocolDefectsWithCost = createSelector(
  selectProtocolLanguage,
  selectOrderedComponents,
  selectIndexedElementsTemplate,
  selectComponentInputsIndexed,
  (language, orderedComponents, indexedElementsTemplate, componentInputsIndexed) => {
    const filteredOrderedComponents = filter(
      (c) =>
        !!c.componentInput && !!c.componentInput.elements && !!c.componentInput.elements.length,
      orderedComponents,
    );

    const filteredElements = reduce(
      (componentsAccumulator, { componentInput, componentTemplate }) => {
        const componentLabel = getLocalizedNameForComponent(
          componentInput,
          componentTemplate,
          language,
        );

        const orderedElements = getOrderedElementsTemplate(
          componentInput.elements,
          componentInput.elementsOrder,
          indexedElementsTemplate,
          componentTemplate,
        );

        const indexedElementInputs =
          componentInput.elements && indexBy(prop('id'), componentInput.elements);

        if (!!orderedElements.length) {
          const elementsCollected = reduce(
            (elementsAccumulator, elementTemplate) => {
              let index = null;
              const elementInput = indexedElementInputs[elementTemplate.id];

              if (!has('elements', elementTemplate)) {
                index = getIndexedElements(
                  componentInputsIndexed,
                  elementTemplate.id,
                  !componentTemplate ? -componentInput.id : componentTemplate.id,
                );
              }
              let filteredElementInput = elementInput;
              if (!!elementInput) {
                filteredElementInput = mergeDeepRight(filteredElementInput, {
                  attributesSelected: filter((a) => !!a.cost)(
                    (!!elementInput.attributesSelected && elementInput.attributesSelected) || [],
                  ),
                });
              }

              if (
                !!filteredElementInput &&
                filteredElementInput.state === 'defect' &&
                any((a) => !!a.cost)(filteredElementInput.attributesSelected || [])
              ) {
                elementsAccumulator.push({
                  index: index,
                  componentLabel: componentLabel,
                  componentId: componentInput.id,
                  elementInput: filteredElementInput,
                  ...elementTemplate,
                });
              }

              return elementsAccumulator;
            },
            [],
            orderedElements,
          );
          return concat(componentsAccumulator, elementsCollected);
        }
        return componentsAccumulator;
      },
      [],
      filteredOrderedComponents,
    );

    return filteredElements;
  },
);

export const selectPersonList = createSelector(selectProtocolSignatures, (signatures) => {
  return {
    oldTenant:
      !!signatures && !!signatures.oldTenant && !!signatures.oldTenant.personList
        ? signatures.oldTenant.personList
        : [],
    newTenant:
      !!signatures && !!signatures.newTenant && !!signatures.newTenant.personList
        ? signatures.newTenant.personList
        : [],
    manager:
      !!signatures && !!signatures.manager && !!signatures.manager.personList
        ? signatures.manager.personList
        : [],
  };
});

export const selectIsProtocolSynchronizationError = createSelector(
  selectCurrentId,
  selectErrorSynchronizationProtocols,
  (currentId, errorSynchronizationProtocols) =>
    errorSynchronizationProtocols.indexOf(currentId) !== -1,
);

export const selectCurrentProtocolTotalImages = createSelector(
  selectComponentsInput,
  (componentsInput) => {
    if (!componentsInput) return;

    let total = 0;

    // Get number of images in the protocol
    forEach(
      (componentInput) =>
        forEach(
          (element) => forEach(() => total++, element.images || []),
          componentInput.elements || [],
        ),
      componentsInput,
    );

    return total;
  },
);
